import React from 'react';

import { useGlobalState } from '../global/global';
import Layout from '../components/layout';

import '../styles/big-data-analytics.css';
import { navigate } from 'gatsby';

const BigDataAnalytics = props => {
    const [state, dispatch] = useGlobalState();

    const handleClick = (id) => {
        navigate('/');
        var checkExist = setInterval(function () {
            if (document.getElementById(id)) {
                document.getElementById(id).scrollIntoView({
                    behavior: 'smooth'
                });
                clearInterval(checkExist);
            }
        }, 100);
    }

    const setImageSrc = (imageName) => {
        if (state.lightThemeState) {
            return '/images/light/' + imageName;
        }

        if (!state.lightThemeState) {
            return '/images/dark/' + imageName;
        }
    }

    return (
        <Layout location={props.location}>
            <div className='big-data-analytics-page'>
                <div className='big-data-analytics-page__top'>
                    <h1 className='big-data-analytics-page__top__title'>Big Data Analytics</h1>
                    <p className='big-data-analytics-page__top__detail'>The big data and analytics market evolve rapidly and large efforts in research and innovation is required to maintain a position. We are working hard to future proof our offerings. Our solutions fit very well with the market and technology development trajectory projected by the top 10 data analytics trends put forward by <u><a href="https://www.gartner.com/smarterwithgartner/gartner-top-10-data-analytics-trends/" target="_blank"> Gartner Group in November 2019</a></u>.</p>
                </div>
                <div className='big-data-analytics-page__line'></div>
                <div className='big-data-analytics-page__bottom'>
                    <div id='augmented-intelligence' className='big-data-analytics-page__bottom__content'>
                        <img className='big-data-analytics-page__bottom__content__image' src={setImageSrc('augmented_intelligence.svg')}  alt='augmented analytics'/>
                        <h3 className='big-data-analytics-page__bottom__content__title'>Augmented Intelligence</h3>
                        <p className='big-data-analytics-page__bottom__content__detail'>Augmented Intelligence solutions are characterized by minimal reliance on data science or machine learning experts to operate and use. The <u onClick={() => navigate('ephesus-suite')}>{'Ephesus suite'}</u> of solutions is a good example of our Augmented Intelligence capabilities.</p>
                    </div>
                    <div id='artificial-intelligence' className='big-data-analytics-page__bottom__content'>
                        <img className='big-data-analytics-page__bottom__content__image' src={setImageSrc('Artificial_intelligence.svg')}  alt='artificial intelligence'/>
                        <h3 className='big-data-analytics-page__bottom__content__title'>Artificial Intelligence</h3>
                        <p className='big-data-analytics-page__bottom__content__detail'>Our solutions make use of state-of-the art artificial intelligence and machine learning techniques. We have ready trained artificial intelligence models for machine learning from a variety of data types such as text, images, audio and video.</p>
                    </div>
                    <div id='blockchain-based-analytics' className='big-data-analytics-page__bottom__content'>
                        <img className='big-data-analytics-page__bottom__content__image' src={setImageSrc('blockchain_based_analytics.svg')}  alt='blockchain based analytics'/>
                        <h3 className='big-data-analytics-page__bottom__content__title'>Blockchain based analytics</h3>
                        <p className='big-data-analytics-page__bottom__content__detail'>Blockchain is the core component of our patented Blocknetwork technology. The Blocknetwork was designed to solve the data management and data lineage problem enabling data analytics solutions with superior accountability, security, data integrity and trust. Blockchain in data and analytics is a key enabling trend predicted by Gartner Group for Data Analytics.</p>
                    </div>
                    <div id='data-fabric' className='big-data-analytics-page__bottom__content'>
                        <img className='big-data-analytics-page__bottom__content__image' src={setImageSrc('information_fusion.svg')}  alt='information fusion'/>
                        <h3 className='big-data-analytics-page__bottom__content__title'>Data Fabric</h3>
                        <p className='big-data-analytics-page__bottom__content__detail'>Data Fabric can be explained as moving beyond analytics from siloed homogenous datasets and instead implement a unified and cohesive analytics environment across multiple streams of heterogenous datasets. <u onClick={() => navigate('/nginr')}>{'nginr'}</u><sup><small>®</small></sup> is our solution to the Data Fabric challenge.</p>
                    </div>
                    <div id='continuous-intelligence' className='big-data-analytics-page__bottom__content'>
                        <img className='big-data-analytics-page__bottom__content__image' src={setImageSrc('continous_intelligence.svg')}  alt='continous intelligence'/>
                        <h3 className='big-data-analytics-page__bottom__content__title'>Continuous Intelligence</h3>
                        <p className='big-data-analytics-page__bottom__content__detail'>The first generation of big data analytics solutions had a hard time to cope with the continuous stream of real-time data. They had to resort to batch processing, one-off and ad-hoc data analytics. Our solutions based on <u onClick={() => navigate('/nginr')}>{'nginr'}</u><sup><small>®</small></sup> are designed to meet the demanding applications where real-time continuous intelligence is a requirement.</p>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default BigDataAnalytics;